input[type="radio"] {
  appearance: none;
  background-color: #dce1e9;
  margin: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.radio-control + .radio-control {
  margin-top: 1em;
}

input[type="radio"]::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 14px 14px #3268b2;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.toggle-button {
  border-radius: 0.5rem;
  padding: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  color: #2B4469;
  font-family: Roboto-Semi-Bold;
  font-size: 0.75rem;
  font-weight: 500;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  text-transform: none;
  margin: 0.25rem 0;
  min-width: 6rem;
  
  &.Mui-selected, &.Mui-selected:hover {
    color: white;
    background-color: transparent;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out; 
  }
}

.toggle-button-group {
  color: #3268B2;
  & .MuiToggleButtonGroup-grouped {
    border: 0;
    &:not(:first-of-type) {
      border-radius: 0.5rem;
      margin-left: 0; /* Override MUI failing styles */
    }
    &:first-of-type {
      border-radius: 0.5rem;
    }
  }
}

.toggle-overlay {
  height: 25px;
  margin: 0.25rem 0 0 0;
  position: absolute;
  background-color: #3268B2;
  transition: transform 0.25s ease-in-out, width 0.25s ease-in-out;
  border-radius: 0.5rem;
  will-change: transform;
  backface-visibility: hidden;
}

.toggle-button-group-container {
  border-radius: 0.5rem;
  background-color: #E8EEF6;
  padding: 0 0.35rem;
}