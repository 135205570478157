// weekDay label letters
.MuiDayCalendar-weekDayLabel {
  color: grey;
  font-size: 0.95rem;
  font-family: 'Roboto-Semi-Bold';

  &:nth-child(6),
  &:nth-child(7) {
    color: rgba(255, 61, 34, 0.7);
  }
}

// month & year label
.MuiPickersCalendarHeader-label {
  color: #2b4469;
  font-size: 2rem;
  font-family: 'Roboto-Semi-Bold';
  height: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  //text-transform: capitalize;
}

.MuiPickersCalendarHeader-root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;
  padding-bottom: 1rem;
}

.MuiPickersCalendarHeader-labelContainer {
  margin-right: 0;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button {
  position: absolute;
  left: 0;
  top: -12px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button {
  position: absolute;
  right: 0;
  top: -12px;
}

.date-time-field-container {
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: #F5F7FB;
}

.date-time-label {
  display: flex;
  height: 100%;
}

.date-label {
  color: #2b4469;
  font-size: 1.5rem;
  font-family: 'Roboto-Semi-Bold';
  height: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

.type-label {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: 'Roboto';
}

.date-time-btn {
  font-family: 'Roboto-Bold';
  font-size: 1.1rem;
  position: relative;
  padding-bottom: 0.2rem;
  text-transform: none;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 3px;
    border-radius: 1rem;
    background-color: currentColor;
    transition: width 0.4s ease-out;
  }

  &:hover::after {
    width: 100%;
  }

  &:active::after {
    width: 100%;
  }
}

.date-time-btn.active::after {
  width: 100%;
}

.date-time-btn-container {
  display: flex;
  justify-content: center;
  height: 2rem;
}

.continue-btn-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1070;
  padding: 0 3rem 1.5rem 3rem;
}

.date-time-picker-container {
  position: relative;
}

// date picker's day styling

.custom-pickers-day:focus,
.custom-pickers-day:hover {
  background-color: transparent;
}

.custom-pickers-day {
  font-size: 0.85rem;
  width: 40px;
  height: 40px;
}

.custom-pickers-day.isHovered,
.custom-pickers-day.isHovered:active,
.custom-pickers-day.isHovered:hover,
.custom-pickers-day.isHovered:focus {
  border-radius: 15px;
  padding: 0;
  color: #3268b2;
  background-color: #d6e7fe;
}

.custom-pickers-day.isBetween {
  position: 'relative';
}

.custom-pickers-day.isBetween::before {
  content: '';
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: -6px;
  right: -6px;
  background-color: #e8eef6;
  border-radius: 0;
  z-index: -1;
}

.custom-pickers-day.isSelected,
.custom-pickers-day.isSelected:active,
.custom-pickers-day.isSelected:hover,
.custom-pickers-day.isSelected:focus {
  border-radius: 15px;
  padding: 0;
  color: #fff !important;
  background-color: #3268b2;
}

.bottom-sheet-without-header [data-rsbs-overlay] [data-rsbs-header] {
  display: none;
}
