.public-booking-notification-container {
  margin: 0 0.5rem;
}

.public-booking-success-notification {
  background-color: #c5efdd33;
  color: #32B27C;
  border: 1px solid #32B27C;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(35, 49, 68, 0.1);
}

.public-booking-error-notification {
  background-color: #FFF9F8;
  color: #FF7D6B;
  border: 1px solid #FF7D6B;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(35, 49, 68, 0.1);
}

.public-booking-notification-text-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0.75rem 2rem;
}

.public-booking-notification-text {
  font-family: 'Roboto-Semi-Bold';
}