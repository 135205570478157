@import "../../../assets/styles/variables";

.SidenavHead {
  padding: 1.55em 0;
  background-color: $paper;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.LocalesDialog {
  .MuiDialog-paper {
    background: $background !important;
  }
}

.LocalesContainer {
  top: 52px;
  padding: 1em;

  .MuiListItem-root {
    background: $surface;
  }
}

.SidenavHeadImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.ListItem {
  min-width: 304px;
  display: block;
  text-decoration: none;
  color: $secondary;
}

.SignOut {
  top: auto;
  text-decoration: none;
  display: flex;
  height: 50px;
  color: $text-color;
  background: $greylight;
}

.loginText {
  color: $surface;

  .MuiListItemText-primary {
    font-weight: 700;
  }

  .MuiListItemText-secondary {
    color: $surface;
  }
}

.UserProfileLink {
  text-decoration: none !important;
}

.menuList {
  overflow: auto;
  max-height: calc(100% - 220px);
}

.switchToPublic {
  top: auto;
  text-decoration: none;
  display: flex;
  height: 50px;
  color: $text-color;
  background: $greylight;
}

.footer-sidenav {
  bottom: 0;
  width: 100%;
}

.body-sidenav {
  height: 100%;
  overflow: auto;
}

.list-item-button {
  cursor: pointer;
}

.sidenav-country-flag {
  width: 20px;
  height: 15px;
  margin-bottom: 1px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.sidenav-language-container {
  display: flex;
  align-items: center;
}