.time-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.time-column {
  height: 10rem;
  padding: 12px;
  cursor: grab;
}

.time-value {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 2rem;
  font-family: 'Roboto-Semi-Bold';
  display: flex;
  align-items: center;
  text-align: center;
}

.time-text {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: 'Roboto-Semi-Bold';
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1050;
}

.selected {
  font-family: 'Roboto-Bold';
  color: #3268B2;
}

.disabled {
  color: #ccc;
  pointer-events: none;
}

.selector-top-border {
  color: #000;
  position: absolute;
  width: 100%;
  padding: 0 2rem 3rem 2rem;
}

.selector-bottom-border {
  color: #000;
  position: absolute;
  width: 100%;
  padding: 3rem 2rem 0 2rem;
  height: 3rem;
  background-color: #F5F6FA;
  border-radius: 0.75rem;
}
