.currency-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.currency-icon-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.currency-icon-circle {
  fill: transparent;
  stroke: #455D80;
  stroke-width: 2.5;
}

.currency-icon-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Montserrat-Medium;
  font-size: 1.4rem;
  color: #455D80;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  clip-path: circle(15px at center);
}