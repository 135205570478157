.booking-payment-footer-white {
  background-color: #fff;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: fixed;
  z-index: 1;
  -ms-touch-action: none;
  touch-action: none;
  box-shadow: 0px 4px 16px 0px rgba(43, 68, 105, 0.2);
  width: 100%;
}
